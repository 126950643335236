@import '~antd/lib/style/themes/default.less';

.explorer-header {
    & .explorer-logo {
        width: 64px;
        height: 64px;
        margin: 10px 10px 10px 0;
    }
    & .explorer-name {
        font-size: 28px;
        color: #fff;
    }

    & .explorer-network {
        color: #fff;
        line-height: 1.0em;
    }

    & .explorer-languages {
        font-size: @font-size-sm;
        width: 100px;
        margin-left: @padding-md;
    }

    & .explorer-header-nav {
        flex-grow: 1;
        border: none;
        background: transparent;

        & .ant-menu-item {
            margin: 0 2px !important;
            padding: @menu-item-padding !important;
            color: #fff;

            &.ant-menu-item-selected {
                color: fade(@primary-color, 80%);
            }
            &::after {
                border: none;
            }

            & a {
                color: inherit;

                &:hover,&:active {
                    color: fade(@primary-color, 70%);
                }
            }
        }
    }
    & .explorer-header-search {
        width: 350px;

        & input::placeholder {
            font-size: 12px;
        }
    }

    & .explorer-header-top {
        display: flex;
        flex-direction: row;
        align-items: center;

        & .explorer-network {
            text-align: right;
            flex-grow: 1;
        }
    }
    & .explorer-header-bottom {
        display: flex;
        flex-direction: row;
        align-items: center;

        & .explorer-header-nav {
            flex-grow: 1;
        }
        & .explorer-header-search {
            width: 350px;

        }
    }
}

.explorer-footer {
    & .explorer-footer-summary {
        padding: 20px 0;
        border-bottom: 1px solid fade(#fff, 20%);

        & .explorer-logo {
            width: 168px;
            height: 64px;
        }
    }

    & .explorer-footer-links {
        font-size: 12px;

        & a {
            color: fade(#fff, 80%);
            padding: 5px 10px;
        }
    }

    & .explorer-footer-copyright {
        font-size: @font-size-sm;
        color: fade(#fff, 40%);
        text-align: center;
        padding: 10px 0;
    }
}

@primary-color: #e2ae5a;@layout-header-background: #1a4fc9;@layout-body-background: #f0f6fb;@layout-footer-background: #1a4fc9;@divider-color: #e2ae5a;