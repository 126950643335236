.explorer-blockview {

    & .explorer-blockview-base {
        & .ant-card-body {
        }
        & .ant-card-head-title .ant-space-horizontal {
            width: 100%;
            justify-content: space-between;
        }

        & .explorer-blockview-base-navi {
            & button {
                font-size: 12px;
            }

            & .explorer-blockview-base-next {
                direction: rtl;
            }
        }
    }

    & .explorer-blockview-txs {
        & .explorer-box {
            font-size: 12px;
            line-height: 2.0em;
        }
        & .explorer-txitem-txs-item {
            font-size: 12px;
        }
    }
}
@primary-color: #e2ae5a;@layout-header-background: #1a4fc9;@layout-body-background: #f0f6fb;@layout-footer-background: #1a4fc9;@divider-color: #e2ae5a;