.explorer-dashboard {
    & .explorer-dashboard-network {
        background: #fff;

        & > .ant-card-body {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 12px;

            &::before {
                content: none;
            }

            & .network-box {
                width: 190px;
                height: 120px;
                background: #f0f6fb;
                border-radius: 2px;
                border: 1px solid #f0f6fb;
                overflow: hidden;
                box-sizing: border-box;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                & .network-box-title {
                    display: block;
                    font-size: 12px;
                    line-height: 1.0em;
                    color: fade(#000, 60%);
                }
                & .network-box-content {
                    font-size: 16px;
                    line-height: 2.0em;
                    overflow: hidden;
                }
            }
        }
    }

    & .explorer-difficultychart-options {
        position: absolute;
        z-index: 100;
        right: 10px;
        top: 10px;
    }
}

@primary-color: #e2ae5a;@layout-header-background: #1a4fc9;@layout-body-background: #f0f6fb;@layout-footer-background: #1a4fc9;@divider-color: #e2ae5a;