.explorer-accounts {
    & .explorer-accounts-options {
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    & .explorer-accounts-summary {
        padding: 20px 10px;
    }
}

@primary-color: #e2ae5a;@layout-header-background: #1a4fc9;@layout-body-background: #f0f6fb;@layout-footer-background: #1a4fc9;@divider-color: #e2ae5a;