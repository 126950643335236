.explorer-txitem {
    margin-bottom: 20px;
    border-radius: 6px;

    & .ant-card-head {
        min-height: auto;
        padding: 0 10px;

        & .explorer-txitem-NO {
            font-size: 12px;
            color: fade(#000, 40%);
            float: right;
        }
    }
    & .ant-card-head-title {
        padding: 8px 4px;
    }

    & .explorer-txitem-txs {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 10px 0;

        & .explorer-txitem-txs-vin,& .explorer-txitem-txs-vout {
            flex-grow: 1;
        }
        & .explorer-txitem-txs-arrow {
            flex-grow: 0;
            padding: 0 20px;
            font-size: 20px;
            font-weight: bold;
            color: #1890ff;
        }

        & .explorer-txitem-txs-item {
            border-bottom: 1px solid #dfe3eb;
            font-size: 14px;

            &.highlight {
                border-bottom: 2px solid @primary-color;
            }

            & .explorer-address {
                font-size: 12px;
            }
            & .explorer-amount {
                float: right;
                color: fade(#000, 70%);
                font-size: 12px;
            }

            & .explorer-in-tx {
                margin-left: 1.0em;
            }

        }

        &.fold {
            overflow: hidden;
            max-height: 80px;
        }
    }
    & .explorer-txitem-txs-folder {
        background: fade(#fff, 50%);
        background: linear-gradient(fade(#fff, 50%), #fff);
        height: 50px;
        position: relative;
        top: -50px;

        &.unfold {
            background: transparent;
        }

        & button {
            position: relative;
            bottom: -60px;
            left: 50%;
            right: 50%;
        }
    }
}
@primary-color: #e2ae5a;@layout-header-background: #1a4fc9;@layout-body-background: #f0f6fb;@layout-footer-background: #1a4fc9;@divider-color: #e2ae5a;