.explorer-addressview {
    & .explorer-addressview-base {

        & .explorer-addressview-warning {
            font-size: 12px;
            margin-left: 10px;

            color: red;
            padding: 4px;
            border: 1px solid #999;
            border-radius: 5px;
            background-color: #eee;
        }

        & .explorer-addressview-coins,
        & .explorer-addressview-userstakingpools,
        & .explorer-addressview-stakingpoolusers {
            font-size: 12px;
            margin-left: 10px;
        }
    }
}
@primary-color: #e2ae5a;@layout-header-background: #1a4fc9;@layout-body-background: #f0f6fb;@layout-footer-background: #1a4fc9;@divider-color: #e2ae5a;