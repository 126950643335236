@import './internal-theme.less';

.explorer-layout {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;

    & .explorer-layout-nav {
        padding: 0;
        margin: 0;
    }

    &>.explorer-layout-content {
        flex-grow: 1;
    }
}

.explorer-layout-h {
    flex-direction: row;
    padding-top: @layout-header-height !important;
    padding-bottom: 0 !important;

    & .explorer-layout-nav {
        width: 150px;
        border-right: 1px solid @border-color-split;
    }

    & .explorer-layout-content {
        padding: @padding-xs;
    }
}

.explorer-layout-v {
    flex-direction: column;

    & .explorer-layout-nav {}

    & .explorer-layout-content {
        padding: @padding-xs 0;
    }
}

.explorer-app {
    & .explorer-box {
        font-size: 14px;
        line-height: 2.5em;
        border-bottom: 1px solid #dfe3eb;

        & .explorer-box-title {
            width: 180px;
            display: inline-block;
        }
    }

    & .explorer-hash, & .explorer-block, & .explorer-transaction, & .explorer-address {
        font-family: Consolas,Monaco,'Lucida Console','Liberation Mono','DejaVu Sans Mono','Bitstream Vera Sans Mono','Courier New', monospace;
        font-size: 12px;
    }
    & .explorer-address-info {
        margin-left: 4px;
    }
    & .explorer-address-tips {
        white-space: nowrap;
        overflow: hidden;
    }

    & .explorer-title {
        font-size: 20px;
    }

    & .ant-table-tbody {
        font-size: 12px;
    }

    & .explorer-pagination {
        padding: 10px;
    }
}