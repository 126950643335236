.explorer-linkable {
    white-space: nowrap;
    
    & .explorer-linkable-btn {
        height: 0.8em;
        width: 0.8em;
        font-size: 0.8em;
        line-height:0.8em;
        color: fade(#000, 30%);

        cursor: pointer;
        margin-left: 4px;
    }
    & .explorer-linkable-copied {
        color: green;
    }
}
@primary-color: #e2ae5a;@layout-header-background: #1a4fc9;@layout-body-background: #f0f6fb;@layout-footer-background: #1a4fc9;@divider-color: #e2ae5a;