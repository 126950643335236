.explorer-difficultychart {

    & .explorer-difficultychart-options {
        background: rgba(255,255,255,0.9);

        & span {
            font-size: 12px;
            line-height: 20px;
        }
    }
}
@primary-color: #e2ae5a;@layout-header-background: #1a4fc9;@layout-body-background: #f0f6fb;@layout-footer-background: #1a4fc9;@divider-color: #e2ae5a;