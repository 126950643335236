/*
@import '~antd/dist/antd.less';
*/
@import '~antd/lib/style/themes/default.less';

@import '../styles/index.less';

.explorer-app {
    &>.explorer-frame-header {
        border-bottom: @btn-border-width @btn-border-style @border-color-base;
        background: fade(@layout-header-background, 100%);
        backdrop-filter: saturate(180%) blur(20px);
        padding: 0;
        height: auto;
    }

    &>.explorer-frame-content {
        width: 100%;
        height: 100%;
        padding: 0;
        background-color: @body-background;

        &>*:first-child {
            min-height: @content-height;
        }
    }

    &>.explorer-frame-footer {
        background: @layout-footer-background;
        color: #fff;
        padding: 0;
    }

    .explorer-header,
    .explorer-frame-content,
    .explorer-footer {
        max-width: 1280px;
        margin: 0 auto;
    }

    .explorer-content {}
}

@primary-color: #e2ae5a;@layout-header-background: #1a4fc9;@layout-body-background: #f0f6fb;@layout-footer-background: #1a4fc9;@divider-color: #e2ae5a;